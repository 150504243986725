import React from "react";
import LayoutDumpster from "../components/layout-dumpster";
import StatesListNotfound from "../components/states-list-notfound";
import ZipFinderSmall from "../components/zip-finder-small";

const NOTFOUNDDUMPSTER = ({ pageContext }) => 
{	
	const env = pageContext.env;
    const thecode = pageContext.code;
    const states = pageContext.states.length > 0 ? pageContext.states : [];
    const codes = pageContext.codes.length > 0 ? pageContext.codes : [];    		
    const seo = 
    {
        url: '404',
        env: env,
        code: thecode,
        page: '404'
    }
    
    return (
		<LayoutDumpster seo={seo} env={env} bgcolor={'#f5f5f5'}>   

            <div className="blog is404">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="post">
                            <h1>Sorry, we couldn't find that page</h1>
                            <p>Sorry, we couldn't find the content you were looking for.</p>
                            <p>To find a {env.site} service in your area, use the list below or the search window to the right.</p>
                            <StatesListNotfound
                                states = {states}                    
                                title = {''}
                                codes = {codes}
                                env = {env}
                            />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sidebar">

                                <div className="block">
                                    <ZipFinderSmall
                                        type = {'small'} 
                                        id = {'block-zip-finder'} 
                                        env = {env} 
                                        code = {thecode}
                                        title = {'Find the Right Pro. Simply.'}
                                        placeholder = {'Enter Zip Code'}
                                        finderopenmodal={false}
                                        button= 'Submit'
                                    />                                 
                                </div>

                                <div className="block">
                                    <div className="services mb-5">
                                        <h3>Select the Service You Need</h3>
                                        <div className="card">
                                            <a href="/dumpster-rental" className="service" data-keygroup="dumpster">Temporary Dumpster Rental</a>
                                            <a href="/junk-removal" className="service" data-keygroup="junk-removal">Full Service Junk Removal</a>
                                            <a href="/commercial-waste-management" className="service" data-keygroup="comercial-trash-service">Permanent Commercial Waste</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
  
        </LayoutDumpster>
	)
};

export default NOTFOUNDDUMPSTER;