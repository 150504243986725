import React from "react"
import Cookies from 'js-cookie' //https://www.npmjs.com/package/js-cookie
import OndeffModal from "./OndeffModal";

export default class ZipFinderSmall extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            zip: '',
            ondeffCode: 'priceAndAvailabilityForm'
        }

        if (this.props.state !== undefined) {
            this.place =
            {
                name: undefined,
                state:
                {
                    name: this.props.state.name,
                    av: this.props.state.av
                }
            }
        }else{
            this.place = this.props.place;
        }
        this.code = this.props.code;
        this.codes = this.props.codes;
        this.service = process.env.GATSBY_DRUPAL_URL !== undefined ? `${process.env.GATSBY_DRUPAL_URL}` : 'https://bookable-api-dev.azurewebsites.net';
        this.site = this.props.env.site !== undefined ? this.props.env.site : 'dumpster';
        this.type = this.props.type;
        this.env = this.props.env;
        this.finderopenmodal = this.props.finderopenmodal;

        //Quote request props
        this.proId = this.props.proid;
        this.initialCommercailData = [{ key: "project_type", value: "509" }]
        this.childRef = React.createRef();
    }

    handleClick = () => {
        if (this.childRef.current) {
          this.childRef.current.triggerChangeShowModal(); // Trigger the child's method
        }
    };

    handleSubmit = event => {
        event.preventDefault();

        const zip = this.state.zip;

        if (!this.validateValue(zip)) {
            this.showError('Zip Code is required');
            return;
        }

        if (!this.validateNumber(zip)) {
            this.showError('The zip code must have 5 number');
            return;
        }

        if (this.props.page == "home") {
            this.getUrl(zip, true);
        } else if (this.env.site === 'dumpster') {
            if (this.code == 'junk-removal') {
                this.setState({ ...this.state, ondeffCode: 'junkRemovalQuoteRequest' });
            } else if (this.code == 'comercial-trash-service') {
                this.setState({ ...this.state, ondeffCode: 'commercialWasteQuoteRequest' });
            } else {
                this.setState({ ...this.state, ondeffCode: 'priceAndAvailabilityForm' });
            }
            this.handleClick()
        } else {
            if (this.finderopenmodal) {
                this.setState({ ...this.state, ondeffCode: 'demolitionQuoteRequest' });
                this.handleClick()
            } else this.getUrl(zip, true);
        }

    }

    handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    validateValue = value => {
        return value !== '' ? true : false;
    }

    validateNumber = value => {
        const regex = new RegExp('^\\d{5}$');
        return regex.test(value);
    }

    getUrl = (zip, redirect) => {
        let endpoint = '';

        let code = this.code;

        switch (this.site) {
            case 'dumpster':
                code = code === 'home' ? 'dumpster' : code;
                endpoint = '/api/v1/kpp/service_types/' + code + '/zip_codes/' + zip;
                break;

            case 'demolition':
                code = code === 'home' || code === 'demolition' ? 'demolition-contractors' : code;
                endpoint = '/api/v1/kpp/keywords/' + code + '/zip_codes/' + zip;
                break;

            default:
                break;
        }

        if (endpoint !== '') {
            const url = this.service + endpoint;
            const headers = {};
            headers['Ocp-Apim-Subscription-Key'] = process.env.GATSBY_API_MANAGMENT_KEY;

            fetch(url,
                {
                    method: 'get',
                    headers: headers
                })
                .then((response) => {
                    if (response.status === 400) {
                        return '/redirect/no-zip-found';
                    }

                    return response.json();
                })
                .then((response) => {
                    this.cookies(zip);

                    this.setState({ 'redirect': response });

                    if (redirect) {
                        this.redirect(response);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    cookies = (zip) => {
        Cookies.set('zipcode', zip);
    }

    showError = text => {
        const input = document.getElementById(this.props.id);

        input.value = '';
        input.placeholder = text;
    }

    redirect = url => {
        if (typeof url !== 'object' && !Array.isArray(url)) {
            window.location.replace('/' + url);
        } else {
            window.location.replace('/redirect/no-zip-found');
        }
    }

    zipTop = () => {
        return (
            <ul class="ziptop">
                <li><img src={'/images/icons/tell-us-about-your-project-icon-1.png'} alt="1" loading="lazy"/> Tell us about your project</li>
                <li><img src={'/images/icons/get-connected-to-demo-pros-icon-2.png'} alt="2" loading="lazy"/> Get connected with matched pros</li>
                <li><img src={'/images/icons/get-free-quotes-easily-icon-3.png'} alt="3" loading="lazy"/> Compare free quotes easily</li>
            </ul>
        )
    }

    getFinder = type => {
                return (
                    <div className="zip-finder-small mb-5">
                        <h3>{this.props.title}</h3>
                        <div className="card">
                            <form onSubmit={this.handleSubmit}>

                                {this.zipTop()}

                                <input
                                    id={this.props.id}
                                    className="form-control"
                                    type="text"
                                    name="zip"
                                    value={this.state.zip}
                                    placeholder="Enter Zip Code"
                                    onChange={this.handleInputChange}
                                    maxLength="5"
                                />

                                <input className="btn btn-primary" type="submit" value={this.props.button} />
                            </form>
                        </div>
                    </div>
                )
    }

    render() {
        return (<>
            {this.getFinder(this.type)}
            <OndeffModal
                proid={this.proId}
                ondeffCode={this.state.ondeffCode}
                ref={this.childRef}
                initialFormData={this.state.ondeffCode === 'commercialWasteQuoteRequest' && this.initialCommercailData }
            />
        </>
        )
    }
}