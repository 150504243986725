import React from "react"

export default class StatesListNotfound extends React.Component 
{    
    constructor(props)
    {
        super(props);

        this.env = this.props.env;
        this.states = this.props.states;           
        this.codes = this.props.codes;
        this.code = this.env.site === 'dumpster' ? 'dumpster' : 'demolition-contractors';
    }

    getUrl = (slug, state) =>
    {        
        let path; 
        
        this.codes.map(code =>
        {                         
            if(code.code === this.code)
            {
                switch (slug) 
                {
                    case 'district-of-columbia':							
                    path = `/district-of-columbia/${code.code}-district-of-columbia-dc`;
                        break;
                
                    default:

                        switch (code.code) 
                        {
                            case 'dumpster': case 'demolition-contractors':
                                path = `/${slug}/index.html`;
                                break;

                            case 'comercial-trash-service':
                                path = `/${slug}/${code.paths.code}/index.html`;
                                break; 
                                
                            default:
                                path = `/${slug}/${code.code}/index.html`;
                            break;
                        }

                        break;
                }
            }
        })       
        
        return <a href={path}>{state.name}</a>
    }

    render() 
    {
        return (
            <section className={"section states-list "+this.env.site}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h3>{this.props.title}</h3>
                        </div>
                    </div>
                    <ul className="all-states">
                    {                       
                        this.states.map((state, index) => 
                        {                                                       
                            return (
                                <li key={index}>
                                    {this.getUrl(state.slug, state)}        
                                </li>
                            )
                        })
                    }
                    </ul>
                </div>
            </section>
        );
    }
}