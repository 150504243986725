import React from 'react';
import Cookies from 'js-cookie' //https://www.npmjs.com/package/js-cookie
import Breadcrumb from './breadcrumb';
import OndeffModal from './OndeffModal';

export default class ZipFinderKpps extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            zip: '',
            ondeffCode: 'priceAndAvailabilityForm'
        }

        if (this.props.state !== undefined) {
            this.place =
            {
                name: undefined,
                state:
                {
                    name: this.props.state.name,
                    av: this.props.state.av
                }
            }
        } else {
            this.place = this.props.place;
        }
        this.code = this.props.code;
        this.codes = this.props.codes;
        this.service = process.env.GATSBY_DRUPAL_URL !== undefined ? `${process.env.GATSBY_DRUPAL_URL}` : 'https://bookable-api-dev.azurewebsites.net';
        this.type = this.props.type;
        this.env = this.props.env;
        this.finderopenmodal = this.props.finderopenmodal;

        //Quote request props
        this.proId = this.props.proid;
        this.startVisible = this.props.startVisible ? true : false;
        this.initialCommercailData = [{ key: "project_type", value: "509" }]
        this.childRef = React.createRef();
    }

    handleClick = () => {
        if (this.childRef.current) {
          this.childRef.current.triggerChangeShowModal(); // Trigger the child's method
        }
    };

    addBreadcrumb = () => {
        if (this.props.state !== undefined || this.props.breadcrumb) {
            return (
                <Breadcrumb
                    code={this.code}
                    place={this.place}
                    codes={this.codes}
                    url={this.props.url} />
            )
        }
    }

    pickBg = () => {
        let bg = 'bk-' + this.code;

        if (bg === 'bk-home') {
            bg = 'bk-home-' + this.env.site;
        }

        return bg
    }



    handleSubmit = event => {
        event.preventDefault();

        const zip = this.state.zip;

        if (!this.validateValue(zip)) {
            this.showError('Zip Code is required');
            return;
        }

        if (!this.validateNumber(zip)) {
            this.showError('The zip code must have 5 number');
            return;
        }

        if (this.env.site === 'dumpster') {
            if (this.code == 'junk-removal') {
                this.setState({ ...this.state, ondeffCode: 'junkRemovalQuoteRequest' });
            } else if (this.code == 'comercial-trash-service') {
                this.setState({ ...this.state, ondeffCode: 'commercialWasteQuoteRequest' });
            } else {
                this.setState({ ...this.state, ondeffCode: 'priceAndAvailabilityForm' });
            }
            this.handleClick()
        } else {
            if (this.finderopenmodal) {
                this.setState({ ...this.state, ondeffCode: 'demolitionQuoteRequest' });
                this.handleClick()
            } else this.getUrl(zip, true);
        }

    }

    handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    validateValue = value => {
        return value !== '' ? true : false;
    }

    validateNumber = value => {
        const regex = new RegExp('^\\d{5}$');
        return regex.test(value);
    }

    getUrl = (zip, redirect) => {
        let endpoint = '/api/v1/kpp/service_types/' + this.code + '/zip_codes/' + zip;
        const url = this.service + endpoint;
        const headers = {};
        headers['Ocp-Apim-Subscription-Key'] = process.env.GATSBY_API_MANAGMENT_KEY;

        fetch(url,
            {
                method: 'get',
                headers: headers
            })
            .then((response) => {
                if (response.status === 400) {
                    return '/redirect/no-zip-found';
                }

                return response.json();
            })
            .then((response) => {
                this.cookies(zip);

                this.setState({ 'redirect': response });

                if (redirect) {
                    this.redirect(response);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    cookies = (zip) => {
        Cookies.set('zipcode', zip);
    }

    showError = text => {
        const input = document.getElementById(this.props.id);

        input.value = '';
        input.placeholder = text;
    }

    redirect = url => {
        if (typeof url !== 'object' && !Array.isArray(url)) {
            window.location.replace('/' + url);
        } else {
            window.location.replace('/redirect/no-zip-found');
        }
    }

    getFinder = type => {
        switch (type) {
            case 'small':
                return (

                    <div className="zip-finder-small mb-5">
                        <div className="card">
                            <form onSubmit={this.handleSubmit}>

                                <input
                                    id={this.props.id}
                                    className="form-control"
                                    type="text"
                                    name="zip"
                                    value={this.state.zip}
                                    placeholder="Enter Zip Code"
                                    onChange={this.handleInputChange}
                                    maxLength="5"
                                />
                                <input className="btn btn-primary" type="submit" value={this.props.button} />
                            </form>
                        </div>
                    </div>

                )

            default:
                return (
                    <div className="zip-finder dumpster smallest">
                        <div className="container-fluid">

                            {this.addBreadcrumb()}

                            <section className="row">

                                <div className={`header-image header-image-home col-12 ${this.pickBg()}`} >
                                    <div className="row justify-content-center align-items-center h-100">
                                        <div className="col-md-12 col-lg-8 hero-title text-center">

                                            <h1>{this.props.title}</h1>
                                            <h3>{this.props.text}</h3>

                                            <form className="row justify-content-center" onSubmit={this.handleSubmit}>
                                                <div className="col-md-12 col-lg-8 col-lg-offset-2 search">
                                                    <div className="input-group" id="searcher">

                                                        <input
                                                            id={this.props.id}
                                                            className="form-control"
                                                            type="search"
                                                            name="zip"
                                                            value={this.state.zip}
                                                            placeholder="Enter Zip Code"
                                                            onChange={this.handleInputChange}
                                                            maxLength='5'
                                                        />

                                                        <button
                                                            type="submit"
                                                            id="getStarted"
                                                            className="btn btn-primary btn-sm">{this.props.button}</button>

                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="row title">
                                <div className="col-12">
                                    <h3 className="justify-content-center text-white">America's #1 Source for Dumpsters and Junk Removal</h3>
                                </div>
                            </section>
                        </div>
                    </div>

                )
        }
    }

    render() {
        return (
            <>
                {this.getFinder(this.type)}
                <OndeffModal
                    proid={this.proId}
                    ondeffCode={this.state.ondeffCode}
                    ref={this.childRef}
                    initialFormData={this.state.ondeffCode === 'commercialWasteQuoteRequest' && this.initialCommercailData }
                />
            </>
        )
    }
}